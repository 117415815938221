import React from "react";
import SignUpHeader from "../signUp/SignUpHeader";

function SignUp() {
  return (
    <>
      <SignUpHeader />
    </>
  );
}

export default SignUp;
