import React from "react";

import BlogHeader from "../blog/BlogHeader";

function Blog() {
  return (
    <>
      <BlogHeader />
    </>
  );
}

export default Blog;
