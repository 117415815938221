import React from "react";
import ProjectsHeader from "../projects/ProjectsHeader";

function Projects() {
  return (
    <>
      <ProjectsHeader />
    </>
  );
}

export default Projects;
