import React from "react";
import NoticeHeader from "../notice/NoticeHeader";

function Notice() {
  return (
    <>
      <NoticeHeader />
    </>
  );
}

export default Notice;
