import React from "react";
import CourseHeader from "../course/CourseHeader";
import CourseDetails from "../course/CourseDetails";

function Course() {
  return (
    <>
      <CourseHeader />
      <CourseDetails />
    </>
  );
}

export default Course;
